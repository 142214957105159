import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ChakraProvider, Box } from '@chakra-ui/react';
import './App.css';
import Dashboard from "./pages/Dashboard/Dashboard";
import About from "./pages/About";
import Mission from "./pages/Mission";
import Team from "./pages/Team";
import Navbar from './components/Navbar';
import Auth from './pages/Auth/Auth';
import Footer from './components/Footer';

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const API_URL = "https://api.heliosdata.io";

  const handleSignup = (user, onSuccess) => {
    axios.post(`${API_URL}/auth/signup`, {
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.email,
      password: user.password
    })
      .then(response => {
        console.log(response.data);

        setAuthenticated(true);
        onSuccess();
      })
      .catch(error => {
        console.error('There was an error signing up!', error);
      });
  }

  const handleLogin = (user, onSuccess) => {
    axios.put(`${API_URL}/auth/login`, {
      email: user.email,
      password: user.password
    })
      .then(response => {
        console.log(response.data);
        setAuthenticated(true);
        setLoginError(false);
        onSuccess();
      })
      .catch(error => {
        setLoginError(true);
        console.error('There was an error logging in!', error);
      });
  }

  const handleLogout = () => {
    setAuthenticated(false);
  }

  const ProtectedRoute = () => {
    if (!authenticated) {
      return <Navigate replace={true} to="/auth" />;
    }
    return <Outlet />;
  };

  const FooterDisplay = () => {
    const location = useLocation();
  
    // Conditionally render the Footer based on the location
    return (location.pathname !== '/dashboard' && location.pathname !== '/auth') ? <Footer /> : null;
  }

  return (
    <ChakraProvider>
      <Router>
        <Navbar handleLogout={handleLogout} authenticated={authenticated} />
        <Box bg="gray.100">
          <Routes>
            <Route path="/" element={<Navigate replace={true} to={authenticated ? "/dashboard" : "/auth"} />} />
            <Route path="/auth" element={<Auth loginError={loginError} handleSignup={handleSignup} handleLogin={handleLogin} />} />
            {authenticated && <Route path="/auth" element={<Navigate replace={true} to="/" />} />}

            {/* protected routes: only access if authenticated */}
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>

            <Route path="/about" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/mission" element={<Mission />} />
          </Routes>
        </Box>
        <FooterDisplay />
      </Router>
    </ChakraProvider>
  );
}

export default App;