import React, { useState } from 'react';
import {
    Flex,
    Text,
    Button,
    Input,
    FormControl,
    Heading,
    Divider,
    Image,
    useBreakpointValue,
} from '@chakra-ui/react';

const SignupForm = ({ handleSubmit, handleSwitchAuthState }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        const user = {
            firstName,
            lastName,
            email,
            password,
        };
        handleSubmit(user);
    };

    const imageHeight = useBreakpointValue({ base: '75px', md: '100px', lg: '125px' });

    return (
        <Flex height={{ base: 'auto', md: '70%', lg: '80%' }} backgroundColor="gray.100" mx={{ base: "3em", md: "6em" }}>
            <Flex
                direction={{ base: 'column', md: 'row' }}
                bg="white"
                rounded="lg"
                boxShadow="lg"
                alignItems="center"
            >
                <Flex
                    width={{ base: '100%', md: '50%' }}
                    borderLeftRadius={{ base: 'none', md: 'lg' }}
                    alignItems="center"
                    textAlign="center"
                    flexDir="column"
                    px={{ base: '1em', md: '2em' }} // Responsive padding
                >
                    <Image src="Helios.png" alt="Helios Logo" height={imageHeight} />
                    <Text mt={"1em"} color="grey" fontSize={{ base: '13px', md: '15px', lg: '17px' }} fontWeight="300">
                        A machine learning platform to detect political violence and protect your team.
                        Sign up to gain access to the dashboard.
                    </Text>
                </Flex>
                <Divider orientation="vertical" display={{ base: 'none', md: 'block' }} />
                <Flex width={{ base: '100%', md: '50%' }}>
                    <form onSubmit={onSubmit} style={{ width: '100%' }}>
                        <Flex flexDirection="column" alignItems="center">
                            <Heading fontSize={{ base: '34px', md: '36px', lg: '40px' }} fontWeight="600" color="red.500" mb={"0.75em"}>
                                Sign Up
                            </Heading>
                            <Flex flexDir="column" justifyContent="center">
                                <Flex mb={"1em"} justifyContent="space-between" width={{ base: '100%', md: '300px', lg: '400px' }}>
                                    <FormControl isRequired width="49%">
                                        <Input
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            placeholder="First Name"
                                            bg="gray.200"
                                            type="text"
                                            fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                            h={{ base: '35px', md: '40px', lg: '45px' }} // More fluid height
                                            pl={"1em"}
                                            borderRadius="5px"
                                        />
                                    </FormControl>
                                    <FormControl isRequired width="49%">
                                        <Input
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            placeholder="Last Name"
                                            bg="gray.200"
                                            type="text"
                                            fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                            h={{ base: '35px', md: '40px', lg: '45px' }} // More fluid height
                                            pl={"1em"}
                                            borderRadius="5px"
                                        />
                                    </FormControl>
                                </Flex>
                                <FormControl isRequired mb={"1em"}>
                                    <Input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Email"
                                        bg="gray.200"
                                        type="email"
                                        fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                        h={{ base: '35px', md: '40px', lg: '45px' }} // More fluid height
                                        w={{ base: '100%', md: '300px', lg: '400px' }}
                                        pl={"1em"}
                                        borderRadius="5px"
                                    />
                                </FormControl>
                                <FormControl isRequired mb={"1em"}>
                                    <Input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Password"
                                        bg="gray.200"
                                        type="password"
                                        fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                        h={{ base: '35px', md: '40px', lg: '45px' }} // More fluid height
                                        w={{ base: '100%', md: '300px', lg: '400px' }}
                                        pl={"1em"}
                                        borderRadius="5px"
                                    />
                                </FormControl>
                            </Flex>
                            <Button
                                cursor="pointer"
                                type="submit"
                                bg="red.400"
                                color="white"
                                _hover={{ bg: 'red.600' }}
                                borderRadius="5px"
                                h={{ base: '30px', md: '35px', lg: '40px' }}
                                w={{ base: '100%', md: '300px', lg: '400px' }}
                                fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                fontWeight="bold"
                                mb={"2em"}
                            >
                                Sign Up
                            </Button>
                            <Flex justifyContent="center" flexDir="row">
                                <Text mr={1} fontSize={{ base: '10px', md: '12px', lg: '14px' }} color="gray.600">
                                    Already have an account?
                                </Text>
                                <Text
                                    _hover={{ color: 'red.600' }}
                                    onClick={handleSwitchAuthState}
                                    fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                    fontWeight="bold"
                                    color="red.400"
                                    cursor="pointer"
                                >
                                    Login Here!
                                </Text>
                            </Flex>
                        </Flex>
                    </form>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default SignupForm;