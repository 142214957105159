import React from 'react';
import {
    Flex,
    Box,
    Button,
    Select,
    Text,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    useDisclosure,
    Modal,
    ModalContent,
    ModalBody,
    Image,
    ModalOverlay,
    Link
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import dayjs from 'dayjs';

const Sidebar = ({ 
    loading, 
    handleSubmit, 
    country, 
    handleChangeCountry,
    date,
    day, 
    setDay, 
    forecastData, 
    actors,
    actor, 
    setActor 
}) => {
    return (
        <Flex py="1.5em" pl={"0.5em"} pr="1.5em" flexDirection="column" justifyContent="center" width="100%">
            <HeatMapLegend />
            <CountrySelector
                loading={loading}
                handleSubmit={handleSubmit}
                country={country}
                handleChangeCountry={handleChangeCountry}
                actors={actors}
                actor={actor}
                setActor={setActor}
            />
            <TimeScaleSlider date={date} day={day} setDay={setDay} />
            <ForecastDetails forecastData={forecastData} country={country} actors={actors} />
            <ForecastCredits />
        </Flex>
    );
};

const HeatMapLegend = () => {
    return (
        <Flex
            flexDir="column"
            background="gray.50"
            width="100%"
            py={"1em"}
            px={"1.5em"}
            mb={"1em"}
            borderRadius="10px"
            justifyContent="center"
        >
            <Text fontWeight={"600"} fontSize={"14px"} mb={"1em"}>
                Conflict Probability
            </Text>
            <Box
                height="10px"
                width="100%"
                background="linear-gradient(to right, white, orange, red)"
                border="0.25px solid #ccc"
                borderRadius={30}
                mb={"0.5em"}
            />
            <Flex justifyContent="space-between" width="100%" fontSize="10px" color="gray.600">
                <Text>0%</Text>
                <Text>20%</Text>
                <Text>40%</Text>
                <Text>60%</Text>
                <Text>80%</Text>
                <Text>100%</Text>
            </Flex>
        </Flex>
    );
};

const CountrySelector = ({ loading, handleSubmit, country, handleChangeCountry, actors, actor, setActor }) => {
    return (
        <Flex
            flexDir="column"
            background="gray.50"
            pt={"1em"}
            pb={"1.5em"}
            px={"1.5em"}
            mb={"1em"}
            borderRadius="10px"
            justifyContent="center"
            width="100%"
        >
            <Text fontWeight="600" fontSize="14px" mb={"1em"}>Country of Conflict</Text>
            <Flex flexDir="column" width="100%">
                <Select
                    value={country}
                    onChange={(e) => handleChangeCountry(e.target.value)}
                    placeholder="Select a country"
                    bg="gray.100"
                    fontSize="12px"
                    h="30px"
                    width="100%"
                    mb={"0.5em"}
                    borderRadius={2.5}
                    icon={<FiChevronDown />}
                    iconSize="15px"
                    focusBorderColor="transparent"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <option value="Ukraine">Ukraine</option>
                </Select>
                <Select
                    value={actor}
                    onChange={(e) => setActor(e.target.value)}
                    placeholder="Select an actor"
                    bg="gray.100"
                    fontSize="12px"
                    h="30px"
                    width="100%"
                    mb={"1em"}
                    borderRadius={2.5}
                    icon={<FiChevronDown />}
                    iconSize="15px"
                    focusBorderColor="transparent"
                    isDisabled={!country || actors.length === 0}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {actors.map((actor, index) => (
                        <option key={index} value={actor}>
                            {actor === "conflict_events" ? "All Actors" : actor}
                        </option>
                    ))}
                </Select>
                <Button
                    backgroundColor="red.500"
                    color="white"
                    h="30px"
                    width="100%"
                    fontSize="12px"
                    borderRadius={2.5}
                    onClick={handleSubmit}
                    _hover={{ bg: 'red.600' }}
                    isDisabled={!actor}
                    isLoading={loading}
                >
                    Submit
                </Button>
            </Flex>
        </Flex>
    );
};

const TimeScaleSlider = ({ date, day, setDay }) => {
    
    const startDate = dayjs(date);
    const endDate = startDate.add(6, 'day');
    const dateArray = Array.from({ length: 7 }, (_, i) => startDate.add(i, 'day').format('MM/DD'));

    return (
        <Box
            flexDir="column"
            background="gray.50"
            py={"1em"}
            px={"1.5em"}
            mb={"1em"}
            borderRadius="10px"
            justifyContent="center"
            width="100%"
        >
            <Text fontWeight="600" fontSize="14px" mb={"0.75em"}>
                Forecast for {startDate.format('MMMM D')} – {endDate.format('MMMM D')}
            </Text>
            <Slider
                min={0}
                max={6}
                step={1}
                value={day}
                onChange={(val) => setDay(val)}
                colorScheme="red"
                ml="0.5em"
                width="95%"
            >
                <SliderTrack bg="gray.300">
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb boxSize={3} bg="red.500" />
            </Slider>

            <Flex
                width="99%"
                justifyContent="space-between"
                alignItems="center"
                fontSize="10px"
            >
                {dateArray.map((date, index) => (
                    <Box key={index}>
                        <Text fontWeight={day === index ? "bold" : "normal"} color={day === index ? 'red.500' : 'gray.600'}>
                            {date}
                        </Text>
                    </Box>
                ))}
            </Flex>
            <Text fontWeight="400" mt="1.5em" color="grey" fontSize="10px">
                Note: Forecast data updates weekly on Wednesdays at 1am EST
            </Text>
        </Box>
    );
};

const ForecastDetails = ({ forecastData }) => {
    const { isOpen: isExplanationOpen, onOpen: onExplanationOpen, onClose: onExplanationClose } = useDisclosure();
    const { isOpen: isPerformanceOpen, onOpen: onPerformanceOpen, onClose: onPerformanceClose } = useDisclosure();

    return (
        <Flex
            flexDir="column"
            background="gray.50"
            px={"1.5em"}
            py={"1em"}
            mb={"1em"}
            borderRadius="10px"
            justifyContent="center"
            width="100%"
        >
            <Text fontWeight="600" fontSize="14px" mb={"1em"}>Forecast Details</Text>
            <Button
                backgroundColor="red.500"
                color="white"
                h="30px"
                width="100%"
                fontSize="12px"
                borderRadius={2.5}
                _hover={{ bg: 'red.600' }}
                mb={"0.5em"}
                isDisabled={!forecastData}
                cursor="pointer"
                onClick={onExplanationOpen}
            >
                Model Explanation
            </Button>
            <ModelDetailsModal
                isOpen={isExplanationOpen}
                onClose={onExplanationClose}
                title="Shap Value Model"
                subtext="Represents how much each of the listed variables contributed to the conflict probability"
                image={forecastData?.feature_importance_plot}
            />
            <Button
                backgroundColor="red.500"
                color="white"
                h="30px"
                width="100%"
                fontSize="12px"
                borderRadius={2.5}
                _hover={{ bg: 'red.600' }}
                mb={"0.5em"}
                isDisabled={!forecastData}
                cursor="pointer"
                onClick={onPerformanceOpen}
            >
                Model Performance
            </Button>
            <ModelDetailsModal
                isOpen={isPerformanceOpen}
                onClose={onPerformanceClose}
                title="Confusion Matrix"
                subtext="Representation of model performance by comparing true vs predicted outcomes over the past 2 months"
                image={forecastData?.confusion_matrix}
            />
        </Flex>
    );
}

const ModelDetailsModal = ({ isOpen, onClose, title, subtext, image }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                borderRadius="lg"
                boxShadow="lg"
                width="70vw"
                maxWidth="1200px"
                height="85vh"
            >
                <ModalBody
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    height="100%"
                    pb="1em"
                >
                    <Flex
                        flexDir="column"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                    >
                        <Text
                            fontSize="32px"
                            fontWeight="600"
                            color="red.500"
                            mb={1}
                        >
                            {title}
                        </Text>
                        <Text
                            textAlign="center"
                            fontSize="18px"
                            fontWeight="300"
                            color="gray.500"
                            mb={"1em"}
                        >
                            {subtext}
                        </Text>
                        <Box
                            borderRadius="md"
                            borderWidth="1px"
                            borderColor="gray.200"
                            height="calc(100% - 120px)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Image
                                src={image}
                                alt={title}
                                objectFit="contain"
                                width="100%"
                                height="100%"
                                maxHeight="100%"
                            />
                        </Box>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const ForecastCredits = () => {
    return (
        <Flex
            flexDir="column"
            background="gray.50"
            px={"1.5em"}
            py={"1em"}
            borderRadius="10px"
            justifyContent="center"
            width="100%"
        >
            <Text fontWeight="600" fontSize="14px" mb={"0.5em"}>Forecast Made Possible By</Text>
            <Flex flexDir="column" fontSize={"13px"}>
                <Link isExternal={true} href="https://www.geoboundaries.org">
                    <Text _hover={{ color: 'red.500', textDecorationColor: "red.500", textDecoration: "underline" }} fontWeight="500">GeoBoundaries</Text>
                </Link>
                <Link isExternal={true} href="https://acleddata.com">
                    <Text _hover={{ color: 'red.500', textDecorationColor: "red.500", textDecoration: "underline" }} fontWeight="500">ACLED</Text>
                </Link>
                <Link isExternal={true} href="https://www.worldpop.org">
                    <Text _hover={{ color: 'red.500', textDecorationColor: "red.500", textDecoration: "underline" }} fontWeight="500">WorldPop</Text>
                </Link>
                <Link isExternal={true} href="https://atmosphere.copernicus.eu">
                    <Text _hover={{ color: 'red.500', textDecorationColor: "red.500", textDecoration: "underline" }} fontWeight="500">Copernicus Climate Change Service</Text>
                </Link>
            </Flex>
        </Flex>
    );
}

export default Sidebar;