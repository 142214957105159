import React, { useState, useEffect } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import axios from 'axios';
import HeatMap from './HeatMap';
import Sidebar from './Sidebar';

const Dashboard = () => {
  const [country, setCountry] = useState('');
  const [showHeatMap, setShowHeatMap] = useState(false);
  const [day, setDay] = useState();
  const [forecastData, setForecastData] = useState();
  const [heatMapData, setHeatMapData] = useState();
  const [actors, setActors] = useState([]);
  const [date, setDate] = useState();
  const [actor, setActor] = useState("");
  const [loading, setLoading] = useState(false);
  const API_URL = "https://api.heliosdata.io";

  useEffect(() => {
    axios.get(`${API_URL}/forecast/most-recent-week`)
      .then((response) => {
        setDate(response.data.most_recent_date);
      })
      .catch((error) => {
        console.error('Error fetching actors:', error);
      });
  }, []);

  useEffect(() => {
    if (date) {
      const currentDate = new Date();
      const dayOfWeek = currentDate.getDay(); // 0 is Sunday, 1 is Monday, etc.
      const dayMapping = {
        3: 0,  // Wednesday
        4: 1,  // Thursday
        5: 2,  // Friday
        6: 3,  // Saturday
        0: 4,  // Sunday
        1: 5,  // Monday
        2: 6   // Tuesday
      };
      setDay(dayMapping[dayOfWeek]);
    }
  }, [date]);

  const handleChangeCountry = (countryVal) => {
    if (countryVal !== '') {
      setCountry(countryVal);
      axios.get(`${API_URL}/actors/${countryVal}`)
        .then((response) => {
          setActors(response.data.actors);
          setDate(response.data.date);
        })
        .catch((error) => {
          console.error('Error fetching actors:', error);
        });
    }
  }

  const handleSubmit = () => {
    if (country !== '' && actor !== '') {
      setLoading(true);
      const date_country_actor = `${date}_${country}_${actor}`;
      axios.get(`${API_URL}/forecast/${date_country_actor}`)
        .then((response) => {
          const limitedHeatMapData = Object.fromEntries(
            Object.entries((response.data)).slice(5,12)
          );
          setHeatMapData(limitedHeatMapData);
          setForecastData(response.data);
          setShowHeatMap(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error('Error fetching forecast data:', error);
        });
    }
    console.log(`Selected Country: ${country}, Selected Actor: ${actor}, Date: ${date}`);
  };  

  return (
    <Flex flexDir="row" ml={4} height="85vh">
      <Box width="27.5vw" overflowY="auto">
        <Sidebar
          country={country}
          handleChangeCountry={handleChangeCountry}
          date={date}
          day={day}
          setDay={setDay}
          handleSubmit={handleSubmit}
          forecastData={forecastData}
          actors={actors}
          actor={actor}
          setActor={setActor}
          loading={loading}
        />
      </Box>
      <Flex overflowY="-moz-hidden-unscrollable" width="72.5vw">
        <HeatMap
          heatMapData={heatMapData}
          day={day}
          showHeatMap={showHeatMap}
        />
      </Flex>
    </Flex>
  );
};

export default Dashboard;