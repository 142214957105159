import React from 'react';
import { Flex, Box, Heading, Text } from '@chakra-ui/react';

const Mission = () => {
  return (
    <Flex justifyContent="center" alignItems="center" backgroundColor="gray.100" minHeight="55vh" py="4em">
      <Box 
        py="3em" 
        px="4em"
        width={{ base: '100%', md: '80%', lg: '60%' }}
        backgroundColor="white" 
        borderRadius="lg" 
        boxShadow="xl"
      >
        <Heading mb="1em" textAlign="center" as="h1" fontWeight="600" fontSize={{ base: '36px', md: '38px', lg: '40px' }} color="red.500">
          Our Mission
        </Heading>
        <Text fontSize={{ base: '15px', md: '17px', lg: '19px' }} lineHeight="1.7" mb={6} color="gray.600" fontWeight="300">
          Each day, thousands of analysts in national security and the private sector are tasked with anticipating political risk and dangerous events.
        </Text>
        <Text fontSize={{ base: '15px', md: '17px', lg: '19px' }} lineHeight="1.7" mb={6} color="gray.600" fontWeight="300">
          An analyst must sift through mountains of data to make recommendations to executives and warfighters — a task rife with human error. Miscalculations can endanger life and property.
        </Text>
        <Text fontSize={{ base: '15px', md: '17px', lg: '19px' }} lineHeight="1.7" mb={6} color="gray.600" fontWeight="300">
          At Helios, we are designing an innovative machine learning platform to mitigate risk and keep your team safe. With state-of-the-art machine learning techniques, we strive to offer real-time forecasts of political violence linked to any malicious group, anywhere in the world.
        </Text>
      </Box>
    </Flex>
  );
};

export default Mission;