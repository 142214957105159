import React from 'react';
import { Flex, Image, IconButton, Text, Link, useBreakpointValue } from '@chakra-ui/react';
import { MdLogout } from "react-icons/md";
import { Link as RouterLink } from 'react-router-dom';
import { FaLinkedin } from 'react-icons/fa';

const Navbar = ({ authenticated, handleLogout }) => {

    // Responsive font sizes and logo width
    const fontSize = useBreakpointValue({ base: '0.75rem', md: '0.875rem', lg: '1rem' });
    const logoWidth = useBreakpointValue({ base: '40%', md: '50%', lg: '60%' });
    const linkedinIcon = useBreakpointValue({ base: '1.2em', md: '1.4em', lg: '1.6em' });
    const logoutIcon = useBreakpointValue({ base: '1.25em', md: '1.5em', lg: '1.75em' });

    return (
        <Flex 
            px={"2em"} 
            minHeight="15vh" 
            backgroundColor="white"
            borderBottomWidth="0.5px"
            borderBottomColor="gray.400"
            backdropFilter="blur(10px)"
            alignItems="center"
            justifyContent="space-between"
        >
            {/* Left Side - Logo */}
            <Flex alignItems="center">
                <RouterLink to={authenticated ? "/dashboard" : "/auth"}>
                    <Image 
                        src="Helios.png" 
                        alt="Helios Logo" 
                        maxWidth={logoWidth}  // Responsive logo width
                        height="auto" 
                    />
                </RouterLink>
            </Flex>

            {/* Right Side - Navigation Links, LinkedIn, and Logout */}
            <Flex alignItems="center">
                {authenticated && (
                    <RouterLink to="/dashboard">
                        <Text
                            mr={"2em"}
                            fontSize={fontSize}  // Responsive font size
                            fontWeight="500"
                            color="black" 
                            cursor="pointer" 
                            _hover={{ background: "none", color: "red" }}
                            transition="color 0.2s ease"
                        >
                            Dashboard
                        </Text>
                    </RouterLink>
                )}
                <RouterLink to="/mission">
                    <Text
                        mr={"2em"}
                        fontSize={fontSize}  // Responsive font size
                        fontWeight="500"
                        color="black" 
                        cursor="pointer" 
                        _hover={{ background: "none", color: "red" }}
                        transition="color 0.2s ease"
                    >
                        Our Mission
                    </Text>
                </RouterLink>
                <RouterLink to="/about">
                    <Text
                        mr={"2em"}
                        fontSize={fontSize}  // Responsive font size
                        fontWeight="500"
                        color="black" 
                        cursor="pointer" 
                        _hover={{ background: "none", color: "red" }}
                        transition="color 0.2s ease"
                    >
                        About Us
                    </Text>
                </RouterLink>
                <RouterLink to="/team">
                    <Text
                        mr={"2em"}
                        fontSize={fontSize}  // Responsive font size
                        fontWeight="500"
                        color="black" 
                        cursor="pointer" 
                        _hover={{ background: "none", color: "red" }}
                        transition="color 0.2s ease"
                    >
                        Our Team
                    </Text>
                </RouterLink>

                {/* LinkedIn Icon */}
                <a 
                    href="https://www.linkedin.com/company/heliosllcompany/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Link 
                        display="flex"
                        alignItems="center"
                        color="black"
                    >
                        <IconButton
                            aria-label="LinkedIn"
                            icon={<FaLinkedin size={linkedinIcon} />}
                            variant="ghost"
                            _hover={{ background: "none", color: "red" }}
                            color="black"
                        />
                    </Link>
                </a>

                {/* Logout Icon */}
                {authenticated && (
                    <IconButton
                        onClick={handleLogout}
                        aria-label="Logout"
                        icon={<MdLogout size={logoutIcon} />}
                        variant="ghost"
                        _hover={{ background: "none", color: "red" }}
                        color="black"
                        ml="2em"
                    />
                )}
            </Flex>
        </Flex>
    );
};

export default Navbar;